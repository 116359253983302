<template>
  <FocusLoop>
    <div class="modal_page center">
      <form class="modal_window d-flex d-col space-between" @submit.prevent="post">
        <div class="d-flex space-between align-center">
          <div class="font-18">
            {{ $t("modal.deleteEvent.title") }}
          </div>
          <s-icon color="grey" @click="closeModal">
            close
          </s-icon>
        </div>
        <div class="">
          <s-radio v-model="radio" class="py-30" vertical :options="options" />

          <label for="" class="font-14 opacity-60">{{
            $t("modal.deleteEvent.heading")
          }}</label>
          <s-text-area :min-height="110" :placeholder="$t('modal.deleteEvent.heading')" class="pt-5" />
          <span class="font-12 opacity-60">{{
            $t("modal.deleteEvent.title")
          }}</span>
        </div>

        <div class="d-flex justify-end mt-40">
          <s-btn type="button" class="mr-10 black--text" @click="closeModal">
            {{ $t("modal.cancel") }}
          </s-btn>
          <s-btn type="submit" class="red">
            {{ $t("modal.delete") }}
          </s-btn>
        </div>
      </form>
    </div>
  </FocusLoop>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { gsap } from 'gsap';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const store = useStore();
const radio = ref(0);

const post = async () => {
  if (radio.value === 0) await store.dispatch("schedule/deleteEventByID");
  if (radio.value === 1) await store.dispatch("schedule/deleteEventByUID");
  store.dispatch("calendar/goToLastCalendarView");
  closeModal();
};

const closeModal = () => {
  gsap.to(".modal_page", {
    opacity: 0,
    duration: 0.3,
    ease: "power2.out",
  });
  gsap.to(".modal_window", {
    scale: 0.7,
    duration: 0.3,
    ease: "power2.out",
    onComplete: () => store.commit("modals/deleteEventConfirmationModal", false),
  });
};

const options = computed(() => [
  $t("modal.deleteEvent.this"),
  $t("modal.deleteEvent.all")
]);

onMounted(() => {
  gsap.from(".modal_page", {
    opacity: 0,
    duration: 0.3,
    ease: "power2.out",
  });

  gsap.from(".modal_window", {
    scale: 0.7,
    duration: 0.3,
    ease: "power2.out",
  });
});
</script>

<style scoped>
.modal_page {
  position: fixed;
  left: 0;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(41, 41, 41, 0.6);
  z-index: 8;
}

.modal_window {
  width: 450px;
  padding: 30px;
  padding-left: 60px;
  padding-right: 60px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: var(--shade1);
  border-radius: 10px;
}

@media only screen and (max-width: 576px) {
  .modal_window {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
}
</style>