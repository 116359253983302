<template>
  <div v-show="show" ref="modal_page" class="drawer_page show_on_mobile">
    <div ref="modal" v-click-outside:mobile_chevron="close" class="drawer_modal">
      <div v-if="email">
        <div v-for="item in items" :key="item.idx" class="d-flex">
          <div v-if="!item.hideOnMcard">
            <button @click="open(item)" class="d-flex align-center item">
              <s-icon color="black">
                {{ item.icon }}
              </s-icon>
              <div class="ml-10 uppercase text-start">
                {{ item.title }}
              </div>
            </button>
          </div>
        </div>
      </div>
      <div v-if="!email">
        <div v-for="item in itemsGuest" :key="item.idx" class="d-flex align-center item" @click="open(item)">
          <s-icon color="white" v-if="!item.hideOnMcard">
            {{ item.icon }}
          </s-icon>
          <div class="ml-10 uppercase" v-if="!item.hideOnMcard">
            {{ item.title }}
          </div>
        </div>
      </div>
      <div class="d-flex justify-end">
        <button :title="$t('toolbars.drawer.info')" @click="openAppInfo">
          <s-icon color="black">
            information-outline
          </s-icon>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { gsap } from "gsap";
import { ref, watch, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const store = useStore();
const router = useRouter();
const { t } = useI18n();

const show = ref(false);
const modal_page = ref(null);
const modal = ref(null);

const items = computed(() => [
  {
    icon: "video-outline",
    title: t("toolbars.drawer.new_meeting"),
    to: "modals/meetingModal",
    action: "modal",
  },
  {
    icon: "home",
    title: t("toolbars.drawer.dashboard"),
    to: "/",
    action: "route",
  },
  {
    icon: "calendar-plus",
    title: t("toolbars.drawer.schedule"),
    to: "/schedule",
    action: "route",
  },
  {
    icon: "calendar-week",
    title: t("toolbars.drawer.calendar"),
    to: "/calendar",
    action: "route",
  },
  {
    icon: "account-supervisor",
    to: "/attendees",
    action: "route",
    title: t("toolbars.drawer.attendees"),
  },
  {
    icon: "cog",
    title: t("toolbars.drawer.settings"),
    to: "modals/accountModal",
    action: "modal",
  },
  {
    icon: "frequently-asked-questions",
    to: "/faq",
    action: "route",
    title: t("toolbars.drawer.faq"),
  },
  {
    icon: "logout",
    title: t("toolbars.drawer.logout"),
    to: "/login",
    action: "function",
    hideOnMcard: enableMCard.value,
  },
]);

const itemsGuest = computed(() => [
  {
    icon: "account-plus",
    title: t("registration.register"),
    to: "/register",
    action: "route",
  },
  {
    icon: "login",
    title: t("registration.login"),
    to: "/login",
    action: "route",
  },
]);

const drawer = computed(() => store.state.toolbars.drawer);
const email = computed(() => store.state.user.email);
const enableMCard = computed(() => store.state.user.enableMCard);


const open = (val) => {
  if (val.action == "route") {
    if (router.currentRoute.value.path != val.to) router.push(val.to);
  }
  if (val.action == "modal") {
    store.commit(val.to, true);
  }
  if (val.action == "function") {
    if (val.icon == "logout") logout();
  }

  store.commit("toolbars/drawer", false);
};

const logout = () => {
  store.dispatch("user/logout");
  store.dispatch("calendar/clearUpcomingEvents");
  router.push("/login");
};

const openAppInfo = () => {
  store.commit("toolbars/toggleDrawer");
  store.commit("modals/appInfo", true);
};

const close = () => {
  store.commit("toolbars/drawer", false);
};

watch(drawer, (val) => {
  show.value = true;
  if (val) {
    gsap.to(modal.value, {
      duration: 0.5,
      x: "0%",
      onUpdate: () => (show.value = true),
    });
    gsap.to(modal_page.value, {
      duration: 0,
      backgroundColor: "#29292999",
    });
  } else {
    gsap.to(modal.value, {
      duration: 0.5,
      x: "100%",
      onComplete: () => (show.value = false),
    });
    gsap.to(modal_page.value, {
      duration: 0,
      backgroundColor: "#29292900",
    });
  }
});

store.commit("toolbars/drawer", false);

</script>


<style scoped lang='less'>
.drawer_page {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 80px;
  width: 100vw;
  height: 101vh;
  background: #d34f4f00;
  z-index: 2;
}

.drawer_modal {
  width: 80%;
  transform: translateX(100%);
  min-height: 475px;
  max-width: 600px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: var(--shade3);
  color: var(--black);
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  box-sizing: border-box;
  justify-content: space-between;
}

.item {
  color: var(--black);
  height: 50px;
  cursor: pointer;
}

.show_on_mobile {
  display: none;
}

@media only screen and (max-width: 576px) {
  .show_on_mobile {
    display: flex;
  }
}
</style>