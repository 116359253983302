<template>
  <div :style="styling"  >
    <label v-if="label">{{ label }}</label>
    <div class="input_text_field">
      <input ref="field" :style="inputStyling" :type="passwordType" autocomplete="off" data-lpignore="true" :disabled="disabled" :value="modelValue || value" :placeholder="placeholder" onkeyup="this.setAttribute('value', this.value);" @focus="onFocus" @blur="onBlur" @input="update">
      <div v-if="password == ''" class="box center" :style="boxStyling" @click="showPassword = !showPassword">
        <s-icon v-show="!showPassword && !disabled" :title="tip && t('atoms.tip.show_password')" width="24" color="green">
          eye-outline
        </s-icon>
        <s-icon v-show="showPassword && !disabled" :title="tip && t('atoms.tip.hide_password')" width="24" color="green">
          eye-off-outline
        </s-icon>
      </div>
    </div>
    <span  class="errorMessage" >{{ error }}</span>
    <div v-if="bar === ''" class="d-flex strength_container">
      <div v-show="strength > 0" class="strength red" />
      <div v-show="strength > 1" class="strength orange" />
      <div v-show="strength > 2" class="strength green" />
      <div v-show="strength > 3" class="strength blue" />
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

const store = useStore();
const { t } = useI18n();
const emit = defineEmits()

const props = defineProps([
  "value",
  "error",
  "label",
  "modelValue",
  "type",
  "details",
  "block",
  "placeholder",
  "height",
  "disabled",
  "textCenter",
  "bar",
  "password",
  "noedit",
  "autofocus",
  "width",
]);

const strength = ref(0);
const field = ref(0);
const showPassword = ref(false);

const tip = computed(() => store.state.user.tip);

const onFocus = () => {
  if (!field.value) return;
  if (props.autofocus == "") field.value.focus()
  emit("focus");
};

const onBlur = () => {
  emit("blur");
};

const update = () => {
  emit('update:modelValue', field.value.value);
};

const passwordStrength = () => {
  strength.value = 0;
  if (props.bar == undefined) return;
  if (!props.bar === "") return;
  if (props.value.length === 0) return strength.value = 0;
  if (!props.value.search(".*[a-z]")) strength.value++;
  if (!props.value.search(".*[A-Z]")) strength.value++;
  if (!props.value.search(".*[0-9]")) strength.value++;
  if (!props.value.search(".*[!@#$&*]")) strength.value++;
  if (props.value.length > 16) strength.value++;
  if (props.value.length < 8) strength.value = 1;
};

const styling = computed(() => {
  let ob = {};
  if (props.block == "" || props.block == "true") ob.width = "100%";
  if (props.details == "" || props.details == "true") ob.minHeight = "55px";

  if (props.label && props.label.length > 0) ob.paddingTop = "0px";
  return ob;
});

const boxStyling = computed(() => {
  let ob = {};
  if (props.height) ob.height = props.height + "px";
  if (props.height) ob.minWidth = props.height + "px";
  if (props.height) ob.maxWidth = props.height + "px";
  return ob;
});

const inputStyling = computed(() => {
  let ob = {};
  if (props.textCenter == "" || props.textCenter == "true") ob.textAlign = "center";
  if (props.height) ob.height = props.height + "px";
  if (props.width) ob.width = props.width + "px";
  if (props.noedit == "") ob.backgroundColor = "#00000000";
  return ob;
});

const passwordType = computed(() => {
  let show = showPassword.value ? "text" : "password";
  return props.password != "" ? props.type : show;
});

watch(() => props.value, (val) => {
  passwordStrength();
});

onMounted(() => {
  if (props.autofocus == "") onFocus();
});

defineExpose({ onFocus, onBlur })
</script>

<style scoped>
.input_text_field {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

label {
  font-size: 16px;
  display: flex;
  transition: all ease 0.2s;
  left: 10px;
  pointer-events: none;
  margin: 0;
  color: rgba(17, 24, 39, 1);
  height: 26px;
  line-height: 22.4px;
}

.icon {
  position: absolute;
  right: 10px;
}

.box {
 position: absolute;
 right: 20px;
}

.red {
  background-color: #df5151;
}

.errorMessage {
  font-size: 14px;
  color: #df5151;
  margin-left: 8px;
}

input {
  height: 50px;
  background-color: var(--shade3);
  font-size: 16px;
  color: var(--shade6);
  width: 100%;
  box-sizing: border-box;
  font-family: Roboto;
  border: 1.5px solid var(--shade4) ;
  padding: 11.66px 19px 11.66px 19px;
  border-radius: 15px;
  border: 1.5px 0px 0px 0px;
  transition: all 0.3s ease;
}

input:hover {
  background-color: var(--shade2);
}

input:focus {
  background-color: var(--shade1);
  font-size: 16px;
  color: var(--black);
  font-weight: 600px;
  outline: none;
  border: 1.5px solid var(--primary);
}

input:focus::placeholder {
  color: var(--shade5);

}

input::placeholder {
  color: var(--shade5);
}

input:disabled::placeholder {
  color: #D8D8D8;
}

input:disabled {
  background-color: #EAEAEA;
  color: white;
}

.strength_container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 4px;
  min-height: 2px;
  margin-top: 5px;
}

.strength {
  width: 100%;
  height: 2px;
  grid-column-gap: 5px;
}

.red {
  background-color: #df5151;
}

.orange {
  background-color: #df9851;
}

.green {
  background-color: #55af6d;
}

.blue {
  background-color: #517cdf;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield !important;
}
</style>
