<template>
  <div :style="styling">
    <div class="input_text_field">
      <textarea ref="field" :style="inputStyling" :type="type" :disabled="disabled" :value="modelValue" :placeholder="placeholder" onkeyup="this.setAttribute('value', this.value);" @focus="onFocus" @blur="onBlur" @input="update" />
      <label>{{ label }}</label>
    </div>
    <span v-show="error" class="errorMessage">{{ error }}</span>
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue';

const props = defineProps([
  "error",
  "label",
  "modelValue",
  "type",
  "details",
  "block",
  "placeholder",
  "height",
  "disabled",
  "minHeight"
]);

const emit = defineEmits();

const field = ref(null);

const styling = computed(() => {
  let ob = {};
  if (props.block == "" || props.block == "true") ob.width = "100%";
  if (props.details == "" || props.details == "true") ob.minHeight = "80px";
  if (props.label && props.label.length > 0) ob.paddingTop = "20px";
  return ob;
});

const inputStyling = computed(() => {
  let ob = {};
  if (props.height) ob.height = props.height + 'px';
  if (props.minHeight) ob.minHeight = props.minHeight + 'px';
  return ob;
});

watch(() => props.value, (newValue) => {
  if (newValue) field.value.setAttribute("value", newValue);
});

const onFocus = () => {
  line.value.style.width = "100%";
};

const onBlur = () => {
  if (!props.error) line.value.style.width = "0";
};

const update = () => {
  emit("update:modelValue", field.value.value);
};
</script>


<style scoped>
.input_text_field {
  position: relative;
  display: flex;
  align-items: center;
}

label {
  font-size: 13px;
  position: absolute;
  transition: all ease 0.2s;
  left: 10px;
  pointer-events: none;
  margin: 0;
  color: rgba(255, 255, 255, 0.8);
}

.icon {
  position: absolute;
  right: 10px;
}

.red {
  background-color: #df5151;
}

.errorMessage {
  font-size: 12px;
  color: #df5151;
  margin-left: 8px;
}

textarea {
  height: 40px;
  background-color: hsla(0, 0%, 0%, 0.05);
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  border: none;
  font-family: roboto;
  border-radius: 10px;
}

textarea:hover {
  background-color: hsla(0, 0%, 0%, 0.1);
}

textarea:focus+label {
  transform: translateY(-28px);
  left: 8px;
  font-size: 10px;
}

textarea[value]:not([value=""])+label {
  transform: translateY(-28px);
  left: 8px;
  font-size: 10px;
}

textarea:focus {
  background-color: hsla(0, 0%, 0%, 0.2);
  font-size: 14px;
  color: rgb(0, 0, 0);
  outline: none;
}

textarea:focus::placeholder {
  color: rgb(0, 0, 0);
}

textarea::placeholder {
  color: rgb(0, 0, 0);
  font-size: 12px;
}

textarea:disabled {
  background-color: rgba(129, 129, 129, 0.0);
}
</style>
