import { get, post } from "../assets/functions";
import store from "../store/index";
import router from "../router";
export default {
    namespaced: true,
    state: {
        email: "",
        firstName: "",
        lastName: "",
        link: "",
        timezones: [],
        userTimezone: "",
        entity_rooms: "",
        entity_currency: "",
        entity_account_type: "",
        eventMaximumAttendees: "",
        entity_stripe_next_billing: {},
        numberOfRooms: "",
        productPrice: "",
        registrationDate: "",
        meetServer: "",
        isTrial: false,
        location: {},
        lang: "en",
        preferredRegion: "",
        tip: true,
        avatar: '',
        routerHistory: [],
        onpremise: false,
        role: "",
        downloads: {},
        enable2fa: false,
        user2FA: {},
        cred: {},
        enableMCard: true,
        theme: 'light',
        hash: ""
    },
    mutations: {
        setUser(state, response) {
            //if (window.location.pathname == '/login' && state.enableMCard) router.push("/")
            if (!response.settings) return;
            let tip = response.settings.tooltips == "yes" ? true : false;
            state.email = response.email;
            state.isTrial = response.isTrial;
            state.firstName = response.firstName;
            state.lastName = response.lastName;
            state.userTimezone = response.settings.timezone == 'UTC' ? 'Europe/London' : response.settings.timezone;
            state.numberOfRooms = response.numberOfRooms;
            state.tip = tip;
            state.meetServer = response.meetServer;
            state.preferredRegion = response.settings.preferredRegion;
            state.registrationDate = response.registrationDate;
            state.avatar = response.avatar;
            state.role = response.role;
           // state.entity_rooms = response.entity?.rooms;
            //state.entity_currency = response.entity?.currency;
            state.accountType = response.accountType;
            //state.entity_stripe_next_billing = response.entity?.stripe?.nextBilling;
            state.user2FA = response.user2FA;
            state.hash = response.hash;
            state.enableMCard = response.isMCard;
            if (!response.email) store.commit("modals/updateProfile", true);
        },
        clear(state) {
            state.email = "";
            state.firstName = "";
            state.lastName = "";
        },
        activeRoom(state, val) {
            state.link = val;
        },
        cred(state, val) {
            state.cred = val;
        },
        enableMCard(state, val) {
            state.enableMCard = val;
        },
        setLang(state, val) {
            state.lang = val;
        },
        downloads(state, val) {
            state.downloads = val;
        },
        config(state, val) {
            if (!val) return;
            state.productPrice = val.product_price;
            state.eventMaximumAttendees = Number(val.max_event_attendees);
            state.enable2fa = val.enable2fa;
            //state.enableMCard = val.enableMCard
        },
        tip(state, val) {
            state.tip = val;
        },
        onpremise(state, val) {
            state.onpremise = val;
        },
        getTimezones(state, response) {
            state.timezones = response;
        },
        userTimezones(state, response) {
            state.userTimezone = response;
        },
        setLocation(state, response) {
            state.location = response;
        },
        routerHistory(state, response) {
            let history = state.routerHistory;
            if (!history || history.length === 0) return state.routerHistory.push(response);
            if (history.length > 10) state.routerHistory.shift();
            state.routerHistory.push(response);
        },
        setTheme(state, data) {
            let theme = data ? data : state.theme
            document.documentElement.setAttribute("data-theme", theme)
            state.theme = theme;
        }
    },
    actions: {
        async logout(context) {
            await get("/logout");
            router.push("/login")
            context.commit("clear");
            return true;
        },
        async register(context, data) {
            return await post("/register", data);
        },
        async getUserInformation(context, data) {
            let response = await get("/api/me");
            //if (!response.id) return store.dispatch("user/logout");
            await context.dispatch("getLocation");
            context.commit("setUser", response);
            if (!data?.lang && response?.settings) context.commit("setLang", response?.settings.lang);
            return response;
        },
        async getTimezones(context) {
            let response = await get("/api/timezone");
            context.commit("getTimezones", response);
        },
        async getConfiguration(context) {
            let response = await get("/api/configuration");
            context.commit("config", response);
        },
        async getDownloads(context) {
            let response = await get("/api/configuration/downloads");
            context.commit("downloads", response);
        },
        async enable2fa(context) {
            return await get("/2fa/g2f/setup");
        },
        async check2fa(context, code) {
            return await post("/2fa/g2f/check", { code });
        },
        async finish2fa(context, code) {
            return await post("/2fa/g2f/finish", { code });
        },
        async cancel2fa(context) {
            let res = await post("/2fa/g2f/cancel");
            context.dispatch("getUserInformation");
            return res;
        },
        async setProfile(context, data) {
            if (!data.firstName) data.firstName = context.state.firstName;
            if (!data.lastName) data.lastName = context.state.lastName;
            let response = await post("/api/me", data);
            await context.dispatch("getUserInformation");
            return response;
        },
        async experienceRating(context, data) {
            return await post("/api/rate", { rating: data });
        },
        async setLang(context, data) {
            if (context.state.email == "") return;
            let body = {
                lang: data,
                firstName: context.state.firstName,
                lastName: context.state.lastName,
                timezone: context.state.userTimezone,
            };
            let response = await post("/api/me", body);
            return response;
        },
        async forgotPassword(context, data) {
            return await post("/api/password", { email: data });
        },
        async resetPassword(context, data) {
            return await post("/api/password/reset", { password: data });
        },
        async validateLink(context, data) {
            return await get("/api/password/" + data);
        },
        async startMeeting(context, data) {
            return await get("/api/startmeeting/" + data);
        },
        async getLocation(context) {
            let response = await get("/api/location");
            context.commit("setLocation", response);
            return response;
        },
        async setLangGuest(context, data) {
            return await post("/api/lang", { lang: data });
        },
        async getLangGuest(context, data) {
            let response = await get("/api/lang");
            if (!response?.lang) response.lang = "en";
            return response;
        },
        isNewRoomValid(context) {
            if (context.state.onpremise) return true;
            let limit = context.state.entity_rooms;
            let current = context.state.numberOfRooms;
            return current < limit;
        },
        async setProfilePicture(context, data) {
            let url = process.env.VUE_APP_URL + '/api/avatar';
            let response = await fetch(url, {
                method: "POST",
                body: data,
                credentials: "include",
            });
            return await response.json();
        },
        async checkIfAccountIsOutOfTrial(context) {
            if (context.state.onpremise) return;
            if (context.state.role !== 'admin') return;
            let isFree = context.state.entity_account_type == "free";
            let isTrial = context.state.isTrial;
            if (!isFree) return;
            if (isFree && isTrial) return;
            store.commit("modals/billingModal", false);
        },
    },
};
